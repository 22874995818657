<template>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Tooltip from 'tooltip.js';

export default {
    name: 'Calendar',
    components: {
        FullCalendar
    },
    props: {
        events: [Array, Function],
        calendarRange: Object,
        height: [Number, String],
        eventRowCount: Number
    },
    data() {
        return {
            records: [],
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                height: this.height,
                buttonText: {
                    today: 'Today'
                },
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                },
                businessHours: {
                    daysOfWeek: [1, 2, 3, 4, 5]
                },
                eventTimeFormat: {
                    meridiem: 'short',
                    hour: 'numeric',
                    minute: '2-digit'
                },
                initialView: 'dayGridMonth',
                validRange: this.calendarRange,
                dateClick: this.handleDateClick,
                eventClick: this.handleEventClick,
                weekends: true,
                events: this.events,
                dayMaxEventRows: this.eventRowCount,
                dayMaxEvents: this.eventRowCount,
                eventsSet: this.setResources,
                eventMouseEnter: this.addToolTip
            }
        };
    },
    methods: {
        handleDateClick: function(clickInfo) {
            const param = {
                clickInfo,
                calenderApi: this.$refs.fullCalendar.getApi(),
                resources: this.resources
            };
            this.$emit('dateClick', param);
        },
        handleEventClick(clickInfo) {
            const param = {
                clickInfo,
                calenderApi: this.$refs.fullCalendar.getApi(),
                resources: this.resources
            };
            this.$emit('eventClick', param);
        },
        setResources(info) {
            this.resources = info;
        },
        addToolTip(info) {
            const arr = document.getElementsByClassName('tooltip');
            Array.from(arr).forEach(element => {
                element.remove();
            });
            if (info.event.extendedProps.toolTipTitle) {
                new Tooltip(info.el, {
                    title: info.event.extendedProps.toolTipTitle,
                    placement: 'top',
                    trigger: 'hover',
                    container: 'body'
                });
            }
        }
    }
};
</script>
<style>
.popper,
.tooltip {
    opacity: 1;
    background: #f6f2f2;
    border-radius: 3px;
}
.tooltip-inner {
    background-color: transparent;
    color: black;
}
.popper[x-placement^='top'],
.tooltip[x-placement^='top'] {
    margin-bottom: 5px;
}
.popper[x-placement^='bottom'],
.tooltip[x-placement^='bottom'] {
    margin-top: 5px;
}
</style>
