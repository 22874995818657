<template>
    <div class="container container-width mb-5">
        <div class="grid">
            <div class="row">
                <div class="col-md-8 col-sm-12">
                    <p class="fw-bold">Visit Location</p>
                    <Dropdown
                        v-model="selectedVisitLocation"
                        :options="visitLocations"
                        @change="onVisitLocationChange"
                        optionLabel="name"
                        placeholder="Select Visit Location"
                        class="w-100"
                        panelClass="dropdown-panel"
                    />
                </div>
                <div class="col-md-2 col-sm-12">
                    <p class="fw-bold">Purpose</p>
                    <Dropdown
                        v-model="selectedSlotState"
                        :options="purposeList"
                        @change="onSlotChange"
                        :optionDisabled="checkDisablePurpose"
                        optionLabel="name"
                        placeholder="Select Purpose"
                        class="w-100"
                    />
                </div>
            </div>
            <div class="mt-1 text-black-50" v-if="selectedVisitLocation">
                <div class="row mt-1">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item">{{ selectedVisitLocation.boroughName }}</li>
                            <li class="breadcrumb-item">{{ selectedVisitLocation.districtName }}</li>
                            <li class="breadcrumb-item">{{ getBuilding() }}</li>
                        </ol>
                    </nav>
                </div>
                <div class="mt-1">
                    {{ getLocation(selectedVisitLocation) }}
                </div>
                <div class="mt-1" v-if="maxDeviceCount">
                    Coverage: Up to {{ maxDeviceCount }} devices.
                </div>
                <div class="mt-1" v-if="memberCount">
                    Team Size: {{ memberCount }}
                </div>
            </div>
        </div>
        <div class="grid mt-3">
            <div class="col-md-12" role="button">
                <Calendar
                    :height="580"
                    :eventRowCount="2"
                    :events="getEvents"
                    :calendarRange="calendarRange"
                    @eventClick="handleSlotClick"
                    v-if="filteredSlots.length > 0 && selectedVisitLocation && selectedSlotState && hasSlots"
                />
            </div>
        </div>
        <ProgressSpinner class="p-component-overlay position-fixed w-100 h-100" strokeWidth="8" v-if="isLoading" />
        <ConfirmDialog group="slotNotAvailableDialog" style="width: 40vw; white-space: pre-line" @hide="refreshCalender()"></ConfirmDialog>
    </div>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Calendar from '../../shared/calendar/Calendar';
import Utility from '../../shared/utility/utility';
import { slotColor, messages, slotStatus, toasterTime } from '../../shared/constants/constants';
import { SlotState, VisitStatus } from '../../shared/enums';
import { VisitLocationService, SlotService, VisitService } from '../../apis';
import { FilterMatchMode } from 'primevue/api';
import { onUnmounted, watchEffect } from 'vue';

import { USER_ROLE } from '../../shared/constants';
const today = new Date();

export default {
    name: 'Schedule',
    components: {
        Calendar,
        Dropdown,
        ProgressSpinner,
        ConfirmDialog
    },
    computed: {
        userDetails() {
            return this.$store.getters.userDetails;
        },
        actionAllowed() {
            const allowedRoles = [USER_ROLE.ADMIN, USER_ROLE.OPS_MANAGER];
            return allowedRoles.includes(this.$store.getters.userDetails.role);
        }
    },
    data() {
        return {
            maxDeviceCount: null,
            memberCount: null,
            cachedVisitLocations: [],
            visitLocations: [],
            selectedVisitLocation: null,
            slots: [],
            filteredSlots: [],
            visits: [],
            selectedSlot: null,
            calendarRange: {
                start: Utility.getStartDayOfMonth(today),
                end: Utility.getEndDayOfMonth(today)
            },
            selectedSlotState: null,
            purposeList: [],
            calendarEvents: [],
            isLoading: false,
            coveredLocations: [],
            requestJson: {},
            hasSlots: true
        };
    },
    mounted() {
        this.fetchSlots();
        this.getVisitLocations();
        this.selectedVisitLocation = null;
        this.selectedSlot = null;
    },
    methods: {
        getLocation(selectedVisitLocation) {
            let locs = '';
            for (let i = 0; i < selectedVisitLocation.coveredLocations.length; i++) {
                locs = `${ locs }${ locs.length ? ',' : '' } ${
                    selectedVisitLocation.coveredLocations[i].locationId
                }@${ selectedVisitLocation.buildingId } - ${
                    selectedVisitLocation.coveredLocations[i].locationName
                }`;
            }
            return locs;
        },
        getBuilding() {
            return this.selectedVisitLocation ?
                // eslint-disable-next-line max-len
                `${ this.selectedVisitLocation.buildingId } (${ this.selectedVisitLocation.buildingAddress.line_1 } ${ this.selectedVisitLocation.buildingAddress.line_2 }, ${ this.selectedVisitLocation.buildingAddress.state }, ${ this.selectedVisitLocation.buildingAddress.zip })` :
                '';
        },
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        getVisitLocations() {
            VisitLocationService.getVisitLocationsByManager(this.userDetails)
                .then(value => {
                    this.visitLocations = value;
                    if (value?.length) {
                        this.selectedVisitLocation = this.visitLocations[0];
                        const stop = watchEffect(() => {
                            if (this.slots.length) {
                                this.onVisitLocationChange();
                                stop?.call();
                            }
                        });
                        onUnmounted(stop);
                    }
                })
                .catch(err => {
                    this.showToaster(messages.scheduleValidation.slotReleasingFailed, 'error');
                });
        },
        async fetchSlots() {
            try {
                this.slots = await SlotService.getSnapshot();
            } catch {
                this.showToaster(messages.scheduleValidation.scheduleFetchingFailed, 'error');
            }
        },
        onVisitLocationChange() {
            this.maxDeviceCount = null;
            this.memberCount = null;
            this.calendarRange = {};
            this.selectedSlotState = null;
            if (this.selectedVisitLocation) {
                this.purposeList = this.selectedVisitLocation.purposeList;
                if (this.purposeList?.length > 0) {
                    this.selectedSlotState = this.purposeList.find(s => !this.checkDisablePurpose(s));
                }
                this.onSlotChange();
            } else {
                this.visitLocations = [];
                this.filteredSlots = [];
            }
        },
        checkDisablePurpose(option) {
            const bookedSlot = this.slots.find(x => x.booking?.by.id && x.booking.purposeId == option.purposeId);
            return !(!bookedSlot || bookedSlot.schedule.end.seconds >= Utility.addInDate(0, 'd').startOf('day').valueOf() / 1000);
        },
        onSlotChange() {
            this.maxDeviceCount = null;
            this.memberCount = null;
            this.hasSlots = true;
            this.calendarRange = {};
            const self = this;
            this.filteredSlots = [];
            if (this.selectedSlotState) {
                setTimeout(function() {
                    self.filteredSlots = self.slots.filter(x => x.schedule.days == self.selectedSlotState.duration);
                }, 10);
            } else {
                this.filteredSlots = [];
                this.purposeList = [];
            }
        },
        getSchedules() {
            const events = [];
            let currentDate = new Date();
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);

            const endDateLimit = Utility.addInDate(1, 'd').toDate();

            this.filteredSlots.sort((a, b) => (b.booking?.by?.id || '').localeCompare(a.booking?.by?.id || ''));
            for (let i = 0; i < this.filteredSlots.length; i++) {
                const slot = this.filteredSlots[i];
                this.slotState = this.getSlotStaus(slot);
                const eventStatus = this.getSlotStaus(slot);
                if (
                    slotStatus.available !== eventStatus &&
                    slot.booking?.visitLocationId &&
                    !(slot.booking.visitLocationId == this.selectedVisitLocation.id &&
                    slot.booking.purposeId == this.selectedSlotState.purposeId)
                ) {
                    continue;
                }


                const event = {
                    id: slot.id,
                    title: slot.booking ? this.getEventTitle(
                        slot.booking.by.name,
                        slot.booking.when.seconds ? slot.booking.when.seconds :
                            Math.floor(slot.booking.when.getTime() / 1000), slot.schedule.days, false): '',
                    allDay: true,
                    start: Utility.convertSecondsToMoment(slot.schedule.start.seconds)?.startOf('day').toDate(),
                    // Need to add 1 days as FullCalendar treat enddate as exlusive
                    end: Utility.addInDate(1, 'd', Utility.convertSecondsToMoment(slot.schedule.end.seconds)?.endOf('day'))?.toDate(),
                    slotState: slot.state,
                    backgroundColor: slotColor[eventStatus],
                    extendedProps: {
                        toolTipTitle: slot.booking ? this.getEventTitle(
                            slot.booking.by.name,
                            slot.booking.when.seconds ? slot.booking.when.seconds :
                                Math.floor(slot.booking.when.getTime() / 1000), slot.schedule.days, true): '',
                        status: eventStatus,
                        bookedBy: slot.booking?.by,
                        duration: slot.schedule.days
                    }
                };
                if (eventStatus!=slotStatus.available) {
                    this.maxDeviceCount = slot.maxDevices;
                    this.memberCount = slot.memberCount;
                }
                if (
                    (
                        events.some(
                            e => e.start?.getTime() === event.start?.getTime()
                        )) ||
                    (eventStatus == slotStatus.available && Utility.isBefore(event.end, endDateLimit))
                ) {
                    continue;
                }
                if (event.slotState == SlotState.InActive) {
                    if (event.start < currentDate) {
                        events.push(event);
                    }
                } else {
                    events.push(event);
                }
            }
            this.hasSlots = events.length > 0;
            return events;
        },
        setCalendarRange(events) {
            if (events.length > 0) {
                this.calendarRange.start = Utility.getStartDayOfMonth(events.map(e => e.start).reduce((a, b) => (a < b ? a : b)));
                this.calendarRange.end = Utility.getEndDayOfMonth(events.map(e => e.end).reduce((a, b) => (a > b ? a : b)));
            }
        },
        getEvents(info, successCallback) {
            const events = this.getSchedules();
            this.setCalendarRange(events);
            successCallback(events);
        },
        async handleSlotClick(param) {
            console.log(param);
            if (!this.actionAllowed) {
                return;
            }
            this.selectedSlot = param.clickInfo.event;
            this.calendarEvents = param.resources;         

            this.coveredLocations.push(this.selectedVisitLocation.coveredLocations.map(x=>x.locationName));
            if (this.selectedSlot.extendedProps.status === slotStatus.available) {
                let endDateLimit = Utility.addInDate(1, 'd').toDate();
                endDateLimit = new Date(endDateLimit.getFullYear(), endDateLimit.getMonth(), endDateLimit.getDate());
                if (this.selectedSlot.end < endDateLimit) {
                    this.showToaster(messages.scheduleValidation.pastSlotBooking, 'info');
                    return;
                }
                const canBookSlot = await this.canBookSlot();
                if (!canBookSlot) {
                    await this.confirmReScheduleSlot();
                } else {
                    this.confirmBookSlot();
                }
            } else {
                const isReleaseAllowed = await this.canReleaseSlot(this.selectedSlot.id);
                if (isReleaseAllowed) {
                    this.confirmReleaseSlot();
                } else {
                    this.showToaster(messages.scheduleValidation.slotCanNotBeReleased.replace('{emailId}',
                        this.$store.getters.getAppSettings.visitRescheduleContactEmail), 'info');
                }
            }
        },
        confirmReleaseSlot() {
            this.$confirm.require({
                message: messages.scheduleValidation.slotReleaseConfirmation.message.replace('{slotName}', this.selectedSlot.title),
                header: messages.scheduleValidation.slotReleaseConfirmation.header,
                accept: () => {
                    this.releaseSlot();
                    this.ifMaxDevices=false;
                    this.ifMaxAgents = false;
                    this.maxDeviceCount = null;
                    this.memberCount = null;
                },
                acceptClass: 'delete-popup-accept-btn',
                rejectClass: 'p-button-outlined'
            });
        },
        confirmBookSlot() {
            this.$confirm.require({
                message: messages.scheduleValidation.slotBookingConfirmation.message
                    .replace('{slotDuration}', this.selectedSlot.extendedProps.duration)
                    .replace('{slotName}', this.selectedSlotState.name)
                    .replace(/,/g, '\n'),
                header: messages.scheduleValidation.slotBookingConfirmation.header,
                accept: () => {
                    this.bookSlot();
                    this.ifMaxDevices=true;
                    this.ifMaxAgents = true;
                },
                acceptClass: 'delete-popup-accept-btn',
                rejectClass: 'p-button-outlined'
            });
        },
        async getReleaseableSlot() {
            const slotIds = this.filteredSlots.map(x => x.id);
            const slots = await SlotService.getByIds(slotIds);
            const bookedSlots = slots.filter(
                x =>
                    x.booking?.by.id &&
                    x.booking.visitLocationId == this.selectedVisitLocation.id &&
                    x.booking.purposeId == this.selectedSlotState.purposeId
            );
            let releaseableSlot;
            for (const bookedSlot of bookedSlots) {
                const canRelease = await this.canReleaseSlot(bookedSlot.id);
                if (canRelease) {
                    releaseableSlot = bookedSlot;
                    break;
                }
            }
            return releaseableSlot;
        },
        async confirmReScheduleSlot() {
            const slotToBeReleased = await this.getReleaseableSlot();
            if (slotToBeReleased) {
                const bookedEvent = this.calendarEvents.find(x => x.id === slotToBeReleased.id);
                this.$confirm.require({
                    message: messages.scheduleValidation.slotReScheduleConfirmation.message
                        .replace('{X}', this.selectedSlot.extendedProps.duration)
                        .replace('{start}', bookedEvent.startStr)
                        .replace('{Y}', this.selectedSlot.extendedProps.duration)
                        .replace('{startDateNow}', this.selectedSlot.startStr),
                    header: 'Confirmation',
                    accept: async () => {
                        await this.reScheduleSlot(slotToBeReleased, bookedEvent);
                        this.ifMaxDevices=true;
                        this.ifMaxAgents=true;
                    },
                    acceptClass: 'delete-popup-accept-btn',
                    rejectClass: 'p-button-outlined'
                });
            } else {
                this.showToaster(messages.scheduleValidation.slotCanNotBeReleased.replace('{emailId}',
                    this.$store.getters.getAppSettings.visitRescheduleContactEmail), 'info');
            }
        },
        slotNotAvailableConfirm() {
            this.$confirm.require({
                group: 'slotNotAvailableDialog',
                message: messages.scheduleValidation.slotNotAvailable.message,
                header: messages.scheduleValidation.slotNotAvailable.header,
                accept: () => {
                    this.refreshCalender();
                },
                acceptLabel: 'OK',
                rejectClass: 'd-none',
                acceptClass: 'delete-popup-accept-btn'
            });
        },
        async reScheduleSlot(bookedSlot, bookedEvent) {
            const result = await this.isSlotAvailable(this.selectedSlot.id);
            if (result) {
                const slot = this.slots.find(x => x.id == bookedSlot.id);
                delete slot.booking;
                this.updateFilteredSlot(slot.id, null);
                delete bookedSlot.booking;
                bookedEvent.setProp('title', '');
                bookedEvent.setProp('backgroundColor', slotColor.available);
                bookedEvent.setExtendedProp('status', slotStatus.available);
                bookedEvent.setExtendedProp('bookedBy', null);
                bookedEvent.setExtendedProp('toolTipTitle', null);
                SlotService.update(bookedSlot.id, bookedSlot, false);
                this.bookSlot();
            } else {
                this.slotNotAvailableConfirm();
            }
        },
        releaseSlot() {
            const slot = this.slots.find(x => x.id == this.selectedSlot.id);
            delete slot.booking;
            this.changeToAvailableStatus();
            this.updateFilteredSlot(slot.id, null);
            SlotService.update(slot.id, slot, false)
                .then(value => {
                    this.showToaster(messages.scheduleValidation.slotReleasedSuccess, 'success');
                })
                .catch(err => {
                    this.showToaster(messages.scheduleValidation.slotReleasingFailed, 'error');
                });
        },
        async isSlotAvailable(slotId) {
            const result = this.slots.find(x => x.id == slotId);
            return !result.booking?.by.id;
        },
        async bookSlot() {
            const slot = this.slots.find(x => x.id == this.selectedSlot.id);
            const devices = slot.maxDevices;
            const agents = slot.memberCount;
            const result = await this.isSlotAvailable(slot.id);
            if (result) {
                slot.booking = {
                    when: new Date(),
                    by: {
                        id: this.userDetails.firebaseId,
                        name: this.userDetails.name
                    },
                    visitLocationId: this.selectedVisitLocation.id,
                    purposeId: this.selectedSlotState.purposeId
                };
                this.updateFilteredSlot(slot.id, slot.booking);
                this.changeToBookedStatus(devices, agents);
                SlotService.update(slot.id, slot, false)
                    .then(value => {
                        this.showToaster(messages.scheduleValidation.slotBookedSuccess, 'success');
                        this.refreshCalender();
                    })
                    .catch(err => {
                        this.showToaster(messages.scheduleValidation.slotBookingFailed, 'error');
                    });
            } else {
                this.slotNotAvailableConfirm();
            }
        },
        changeToBookedStatus(devices, agents) {
            this.selectedSlot.setProp('title', this.getEventTitle(this.userDetails.name, Math.floor(Date.now() / 1000),
                this.selectedSlot.extendedProps.duration, false));
            this.selectedSlot.setProp('backgroundColor', slotColor.bookedByCurrentUser);
            this.selectedSlot.setExtendedProp('status', slotStatus.bookedByCurrentUser);
            this.selectedSlot.setExtendedProp('bookedBy', this.userDetails.firebaseId);
            this.selectedSlot.setExtendedProp('toolTipTitle', this.getEventTitle(this.userDetails.name, Math.floor(Date.now() / 1000),
                this.selectedSlot.extendedProps.duration, true));
            this.maxDeviceCount = devices;
            this.memberCount = agents;
        },
        changeToAvailableStatus() {
            this.selectedSlot.setProp('title', '');
            this.selectedSlot.setProp('backgroundColor', slotColor.available);
            this.selectedSlot.setExtendedProp('status', slotStatus.available);
            this.selectedSlot.setExtendedProp('bookedBy', null);
            this.selectedSlot.setExtendedProp('toolTipTitle', null);
        },
        getSlotStaus(slot) {
            if (slot.booking?.by?.id) {
                return slot.booking.by.id === this.userDetails.firebaseId ? slotStatus.bookedByCurrentUser : slotStatus.bookedByOther;
            } else {
                return slotStatus.available;
            }
        },
        updateFilteredSlot(slotId, bookingData) {
            const slot = this.filteredSlots.find(x => x.id == slotId);
            if (slot) {
                if (bookingData) {
                    slot.booking = bookingData;
                } else {
                    delete slot.booking;
                }
            }
        },
        refreshCalender() {
            /*  we need to save selected Slot State before refreshing visit location dropdown
              we will assign back this temporary variable once visit location dropdown is refreshed */
            const slot = this.selectedSlotState;
            this.onVisitLocationChange();
            this.selectedSlotState = slot;
            this.onSlotChange();
        },
        async fetchSlotVisits(events) {
            const bookedSlotIds = events.map(x => x.id);
            const result = await VisitService.getVisitBySlotId(bookedSlotIds);
            this.visits = result;
        },
        getEventTitle(userName, date, days, completeTitle) {
            return `scheduled by ${ userName } ${ days!==1 || completeTitle ? 'at '+ Utility.formateDate(date): '' }`;
        },
        async canBookSlot() {
            const slotFilter = { filters:
                    {
                        'booking.visitLocationId': { value: this.selectedVisitLocation.id, matchMode: FilterMatchMode.EQUALS },
                        'booking.purposeId': { value: this.selectedSlotState.purposeId, matchMode: FilterMatchMode.EQUALS }
                    }
            };
            const slots = await SlotService.getAll(slotFilter);
            return slots.length == 0;
        },
        async canReleaseSlot(slotId) {
            const visitFilter = { filters:
                        {
                            'schedule.slotId': { matchMode: FilterMatchMode.EQUALS, value: slotId },
                            'state': { matchMode: FilterMatchMode.IN, value: [
                                VisitStatus.Draft,
                                VisitStatus.Scheduled,
                                VisitStatus.Completed] }
                        }
            };
            const visits = await VisitService.getAll(visitFilter);
            return visits.length == 0;
        }
    }
};
</script>

<style>
.p-dropdown-panel.dropdown-panel {
    width: 62vw !important;
}
.fc-direction-ltr .fc-daygrid-event-harness .fc-daygrid-event.fc-event-start {
    border-top-left-radius: 2rem !important;
    border-bottom-left-radius: 2rem !important;
}
.fc-direction-ltr .fc-daygrid-event-harness .fc-daygrid-event.fc-event-end {
    border-top-right-radius: 2rem !important;
    border-bottom-right-radius: 2rem !important;
}
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.625rem;
}
.p-progress-spinner-svg {
    width: 100px !important;
}
</style>
